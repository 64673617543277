import React from 'react'
import { useTranslation } from 'react-i18next'
import { SEO, MessageScreen, Layout } from '../../ui'
import useStyles from './page-not-found-style'

function PageNotFoundView({
  seoTitle,
}) {
  const { t } = useTranslation()
  const styles = useStyles()
  return (
    <Layout>
      <SEO title={seoTitle} />
      <div className={styles.containerFluid}>
        <MessageScreen
          title={t('screens.404.title')}
          message={t('screens.404.message')}
          button={{
            text: t('screens.404.button'),
            navigate: '/',
          }}
          subMessage={t('screens.404.help')}
        />
      </div>
    </Layout>
  )
}

export default PageNotFoundView
