import React from 'react'
import { useTranslation } from 'react-i18next'
import PageNotFoundView from './page-not-found-view'

function PageNotFoundController() {
  const { t } = useTranslation()
  // local variable
  const seoTitle = t('screens.404.seo.title')
  const viewProps = {
    seoTitle,
  }

  return (
    <PageNotFoundView {...viewProps} />
  )
}

export default PageNotFoundController
