import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[5, 20, 0]],
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    containerFluid: {
      padding: [[46, 0, 120]],
      width: '100%',
      textAlign: 'center',
    },
  },
}))

export default useStyles
